<template lang="pug">
.form-modal(v-if="showFormManager.customHTMLSettings")
  .content.custom-html-settings-modal(@mousedown.stop="")
    .header.pb-4
      .button.om-close(@click="hideModal()" style="position: absolute; top: 0; right: 0") &times;
      .om-row.px-4
        .om-col.om-col-7.px-0
          .om-title-field.mb-0 {{ $t('HTMLcode') }}
    .px-4
      #customHTMLEditor.w-100 {{ html }}
    .om-row.footer.px-4
      .om-col.om-col-6.mr-auto.pl-0
        .font-size-0--875(v-html="$t('customHTMLHelp')")
      .om-col.om-col-2
        .button.button-link(@click="hideModal()") {{ $t('cancel') }}
      .om-col.om-col-3.pr-0
        .button.button-primary.button-large(@click="save") {{ $t('save') }}
</template>

<script>
  import { mapState, mapMutations } from 'vuex';

  export default {
    data() {
      return {
        html: '',
        editor: null,
      };
    },
    computed: {
      ...mapState(['selectedElement', 'showFormManager']),
    },
    watch: {
      'showFormManager.customHTMLSettings': function (v) {
        if (v) {
          this.init();
        } else {
          this.html = '';
        }
      },
    },
    mounted() {
      if (this.showFormManager.customHTMLSettings) this.init();
      else this.html = '';
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      init() {
        this.setHTML();
        this.initAce();
      },
      setHTML() {
        this.html =
          (this.selectedElement && this.selectedElement.data && this.selectedElement.data.html) ||
          this.$t('defaultHTML');
      },
      initAce() {
        this.$nextTick(() => {
          this.editor = window.ace.edit('customHTMLEditor');
          this.editor.getSession().setMode(`ace/mode/html`);
          this.editor.$blockScrolling = Infinity;
          this.editor.setShowPrintMargin(false);
          this.editor.focus();
          this.editor.setValue(this.html);
          this.editor.navigateFileEnd();

          this.editor.on('change', () => {
            this.html = this.editor.getValue();
          });
        });
      },
      htmlHasError() {
        const annotations = this.editor.getSession().getAnnotations();
        return this.html.length === 0 || annotations.some((o) => o.type === 'error');
      },
      save() {
        if (this.htmlHasError()) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.htmlValidationError'),
          });
          return;
        }

        this.html = this.html.trim();

        this.$store.commit('updateData', {
          property: 'selectedElement.data.html',
          value: this.html,
        });

        this.hideModal();
      },
      hideModal() {
        this.changeFormManagerVisibility({ hide: 'customHTMLSettings' });
      },
    },
  };
</script>

<style lang="sass">
  .form-modal
    .custom-html-settings-modal
      width: 50rem
  #customHTMLEditor
    height: 350px
    border: 1px solid #edeff1
</style>
